import { z } from 'zod';

import { StripeStatus } from './userData';

export const BaseUserSchema = z.object({
  username: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  picture_url: z.string().optional()
});

const LicenseRetrieveSchema = z.object({
  emission_date: z.string().optional(),
  expired_date: z.string().optional(),
  license_number: z.coerce.number().or(z.string()).optional(),
  images: z.array(
    z.object({
      url: z.string(),
      filename: z.string(),
      type: z.enum(['front', 'back'])
    })
  )
});

const LicenseUpdateSchema = z.object({
  emission_date: z.string().optional(),
  expired_date: z.string().optional(),
  license_number: z.coerce.number().or(z.string()).optional(),
  images: z.array(
    z.object({
      filename: z.string(),
      imageId: z.string(),
      type: z.enum(['front', 'back'])
    })
  )
});

const RawUserDataSchema = BaseUserSchema.extend({
  contactPoint: z
    .object({
      city: z.string(),
      province: z.string(),
      street: z.string(),
      street_number: z.string(),
      _id: z.string()
    })
    .optional(),
  email: z.string(),
  dni: z.string().optional(),
  phone: z.string().optional(),
  provider: z.string(),
  street: z.string().optional(),
  postal_code: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  province: z.string().optional(),
  is_verified: z.boolean().default(false),
  verified_date: z.string().optional(),
  published_enabled: z.boolean().default(false),
  published_date: z.string().optional(),
  birth_date: z.string().optional(),
  number: z.coerce.number(),
  stripe: z.object({
    identity_verification_status: z.nativeEnum(StripeStatus)
  })
});

export const UserDataSchema = RawUserDataSchema.extend({
  license: LicenseRetrieveSchema.optional(),
  _id: z.string()
});

export const UserUpdateDataSchema = RawUserDataSchema.extend({
  license: LicenseUpdateSchema.optional()
});

export type RentennialsUserData = z.infer<typeof UserDataSchema>;

export type RentennialsUserUpdateData = z.infer<typeof UserUpdateDataSchema>;

export type RentennialsLicenseUpdateData = z.infer<typeof LicenseUpdateSchema>;
