import {
  BasicProfileFormData,
  CompletionProfileStatus,
  LicenseProfileData,
  User,
  UserProfile,
  UserProfileUpdate
} from '@api/types/userData';
import { checkDefinedValues } from '@utils/checkDefinedValues';

const buildProfileBasicFormData = ({ userData, userAddress }: User): BasicProfileFormData => {
  const profileBasicData = {
    name: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    country: userAddress.country,
    document: userData.document,
    birthDate: userData.birthDate,
    phone: userData.phone
  };

  return {
    ...profileBasicData,
    status: checkDefinedValues(profileBasicData) ? CompletionProfileStatus.COMPLETED : CompletionProfileStatus.NONE
  };
};

const buildProfileLicenseFormData = ({
  images,
  expirationDate,
  emissionDate
}: User['userDrivingLicense']): LicenseProfileData => {
  const licenseImages = {
    frontImage: images.find(({ type }) => type === 'front')?.url,
    backImage: images.find(({ type }) => type === 'back')?.url,
    expirationDate,
    emissionDate
  };

  return {
    ...licenseImages,
    status: checkDefinedValues(licenseImages) ? CompletionProfileStatus.COMPLETED : CompletionProfileStatus.NONE
  };
};

export const buildProfileFormUser = (user: User): UserProfile => {
  const profileFormData = {
    basicData: buildProfileBasicFormData(user),
    licenseData: buildProfileLicenseFormData(user.userDrivingLicense)
  };

  let reviewStatus = CompletionProfileStatus.NONE;

  if (
    profileFormData.basicData.status === CompletionProfileStatus.COMPLETED &&
    profileFormData.licenseData.status === CompletionProfileStatus.COMPLETED
  ) {
    reviewStatus = CompletionProfileStatus.UNDER_REVIEW;
  }

  // if (user.userVerification.isVerified && user.userVerification.identityVerificationStatus === StripeStatus.VERIFIED)

  if (user.userVerification.isVerified) {
    reviewStatus = CompletionProfileStatus.VERIFIED;
  }

  return {
    ...profileFormData,
    profileReviewStatus: reviewStatus
  };
};

export const buildUserFromProfile = (user: User, userProfile: UserProfileUpdate) => {
  const { basicData, licenseData } = userProfile;

  const nextUser: User = {
    ...user,
    completionType: 'incomplete',
    userAddress: {
      ...user.userAddress,
      country: basicData.country,
      phone: basicData.phone
    },
    userData: {
      ...user.userData,
      email: basicData.email,
      firstName: basicData.name,
      lastName: basicData.lastName,
      birthDate: basicData.birthDate?.split('T')[0],
      document: basicData.document
    },
    userDrivingLicense: {
      emissionDate: licenseData.emissionDate?.split('T')[0],
      expirationDate: licenseData.expirationDate?.split('T')[0],
      images: licenseData.images
        ? licenseData.images.map(({ imageId, type, filename }) => ({ url: imageId, type, imageId, filename }))
        : user.userDrivingLicense.images
    }
  };
  return nextUser;
};
