import { FC, PropsWithChildren, useRef } from 'react';
import { Fade, useOutsideClick } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { MotionStyle } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import Button from './Button';

interface FloatingMenuProps {
  isOpen: boolean;
  onClose: () => void;
  className: string;
  style?: MotionStyle;
}

const FloatingMenu: FC<PropsWithChildren<FloatingMenuProps>> = ({ onClose, isOpen, className, children, style }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: menuRef,
    handler: onClose
  });

  return (
    <Fade in={isOpen} className={twMerge('absolute top-4 z-20', className)} style={style}>
      <div
        ref={menuRef}
        aria-label="menu-options"
        className="relative rounded-3xl border-[0.5px] bg-white py-6 shadow-md"
      >
        <div className="absolute top-[12px] h-0 w-full px-4 text-right ">
          <Button onClick={onClose} className="rounded-lg p-1 hover:bg-gray-100">
            <XMarkIcon className="inline h-5 w-5 stroke-2 text-[#B7B7B7]" />
          </Button>
        </div>
        {children}
      </div>
    </Fade>
  );
};

export default FloatingMenu;
