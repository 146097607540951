import { DateTime, DurationLikeObject, DurationUnit, Interval, Settings } from 'luxon';

export const rentennialsTimeUtils = (() => {
  const dt = DateTime;
  const dtSettings = Settings;

  dtSettings.defaultZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const setNewTZ = (tz: string) => {
    const prevDefaultTz = dtSettings.defaultZone;
    dtSettings.defaultZone = tz;
    if (!dtSettings.defaultZone.isValid) {
      dtSettings.defaultZone = prevDefaultTz;
    }
  };

  const getDateTime = () => {
    dt.local().zoneName;
    return dt.fromJSDate(new Date());
  };

  const sanitizieDates = (date: DateTime, defaultDate?: DateTime) => {
    if (!date.isValid) {
      return defaultDate ? defaultDate : getDateTime();
    }
    return date;
  };

  const isOnIso = (stringDate: string) => {
    return stringDate.endsWith('.000Z') || stringDate.endsWith('0000');
  };

  const convertToDateTime = (isoDate: string | Date | DateTime, defaultDate?: DateTime, skipIsoCheck?: boolean) => {
    if (isoDate instanceof DateTime) {
      return isoDate;
    }
    if (typeof isoDate === 'string') {
      let nextIsoDate = dt.fromISO(isoDate);
      if (isOnIso(isoDate) && !skipIsoCheck) {
        nextIsoDate = nextIsoDate.setZone('UTC');
      }
      return sanitizieDates(nextIsoDate, defaultDate);
    }
    return dt.fromJSDate(isoDate);
  };

  const addToDateTime = (isoDate: string | Date | DateTime, unit: keyof DurationLikeObject, amount: number) => {
    return (isoDate instanceof DateTime ? isoDate : convertToDateTime(isoDate)).plus({ [unit]: amount });
  };

  const subtractToDateTime = (isoDate: string | Date | DateTime, unit: keyof DurationLikeObject, amount: number) => {
    return (isoDate instanceof DateTime ? isoDate : convertToDateTime(isoDate)).minus({ [unit]: amount });
  };

  const isTodayOrTomorrow = (stringDate: string | Date | DateTime, hGap: number = 0, providedTZ?: string) => {
    // console.log('recibe ', stringDate)
    let updateTZ = false;
    const currentTZ = dt.local().zoneName!;
    if (providedTZ && providedTZ !== dt.local().zoneName) {
      updateTZ = true;
      setNewTZ(providedTZ);
    }
    try {
      const today = getDateTime();
      // console.log('TODAY ', today);
      const fixedDate =
        typeof stringDate === 'string' || stringDate instanceof Date
          ? addToDateTime(stringDate, 'hours', hGap)
          : stringDate.plus({ hour: hGap });

      if (today.month === fixedDate.month && today.day === fixedDate.day) {
        return {
          isToday: true,
          isTomorrow: false
        };
      }

      const todayWithGap = today.plus({ hours: hGap });

      const isTomorrowCondition =
        (todayWithGap.month === fixedDate.month &&
          (todayWithGap.day === fixedDate.day || todayWithGap.day + 1 === fixedDate.day)) ||
        (todayWithGap.month + 1 === fixedDate.month && todayWithGap.day === 31 && fixedDate.day === 1);

      if (isTomorrowCondition) {
        return {
          isToday: false,
          isTomorrow: true
        };
      }

      return {
        isToday: false,
        isTomorrow: false
      };
    } finally {
      if (updateTZ) {
        setNewTZ(currentTZ);
      }
    }
  };

  const getOffset = () => (dt.local().offset / 6) * 10;

  const getFixedOffset = () => {
    const offsetNumber = getOffset();
    if (offsetNumber > 0) {
      return `+${offsetNumber < 1000 ? `0${offsetNumber}` : offsetNumber}`;
    } else if (!offsetNumber) {
      return '+0000';
    }
    return `${offsetNumber * -1 < 1000 ? `-0${offsetNumber * -1}` : offsetNumber}`;
  };

  const differenceBetween = <T extends DurationUnit>(
    finalDate: string | Date | DateTime,
    initialDate: string | Date | DateTime,
    units: T[]
  ): Duration => {
    const finalDateTime = finalDate instanceof DateTime ? finalDate : convertToDateTime(finalDate);
    const initialDateTime = initialDate instanceof DateTime ? initialDate : convertToDateTime(initialDate);
    return finalDateTime.diff(initialDateTime, units);
  };

  const intervalBetween = (start: DateTime, end: DateTime) => {
    const interval: DateTime[] = [];
    Array.from({ length: Interval.fromDateTimes(start, end).count('days') - 1 }).forEach((_, index) => {
      const date = start.plus({ days: index + 1 });
      interval.push(date);
    });

    return interval;
  };

  const fixTimePortion = (timePortion: number) => `${timePortion < 10 ? '0' : ''}${timePortion}`;
  const assembleFixedTime = (hour: number, minute: number) => `${fixTimePortion(hour)}:${fixTimePortion(minute)}`;

  const convertFromUTCToTZ = (date: string, time: string, tz: string) => {
    const tzDate = dt.fromISO(`${date}T${time}:00.000Z`).setZone(tz);
    return {
      isoDate: tzDate.toISO()!,
      date: tzDate.toISODate()!,
      time: tzDate.toISOTime()!,
      fixedTime: assembleFixedTime(tzDate.hour, tzDate.minute),
      hour: tzDate.hour,
      minute: tzDate.minute,
      datePart: tzDate.hour >= 12 ? 'PM' : 'AM',
      isoDateFormat: tzDate.toISO()!
    };
  };

  const convertToUTC = (date: string | Date | DateTime) => {
    return (date instanceof DateTime ? date : convertToDateTime(date)).setZone('UTC');
  };

  const getBrowserTZ = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const setDefaultTZ = () => {
    setNewTZ(getBrowserTZ());
  };
  const getCurrentTZ = () => {
    return getDateTime().zoneName!;
  };

  const getDateInBrowserTZ = () => {
    return dt.fromJSDate(new Date()).setZone(getBrowserTZ());
  };

  const convertVehicleDateToBrowserDateAddingBrowserOffset = (date: string | Date | DateTime) => {
    const nextDate: DateTime = convertToUTC(date);
    const vehicleDate = getDateTime();
    const hourOffsetDiff = (vehicleDate.offset - getDateInBrowserTZ().offset) / 60;
    // TODO check here if this if is required
    if (hourOffsetDiff > 0) {
      return addToDateTime(nextDate, 'hour', hourOffsetDiff);
    }
    return nextDate;
  };

  const convertVehicleDateToBrowserDateStartOfDay = (date: string | Date | DateTime) => {
    const stringDate = convertToDateTime(date).toISO()!.split('.')[0];
    const currentZoneName = getCurrentTZ();
    setNewTZ(getBrowserTZ());
    const convertedDate = dt.fromISO(stringDate).startOf('day');
    setNewTZ(currentZoneName);
    return convertedDate;
  };

  const convertBrowserDateToVehicleDateStartOfDay = (browserDate: string | Date | DateTime) => {
    const nextDate = convertToDateTime(browserDate).setZone(getBrowserTZ()).startOf('day');
    const nextDateString = nextDate.toISO()!;
    return dt.fromISO(nextDateString.split('.')[0]).startOf('day');
  };

  return {
    getDateTime,
    getDateInBrowserTZ,
    operations: {
      addToDateTime,
      subtractToDateTime,
      differenceBetween,
      intervalBetween
    },
    transform: {
      convertToDateTime,
      convertFromUTCToTZ,
      convertVehicleDateToBrowserDateAddingBrowserOffset,
      convertVehicleDateToBrowserDateStartOfDay,
      convertBrowserDateToVehicleDateStartOfDay,
      convertToUTC
    },
    offset: {
      getFixedOffset,
      getOffset,
      setNewTZ,
      setDefaultTZ,
      getBrowserTZ,
      getCurrentTZ
    },
    isTodayOrTomorrow,
    dt
  };
})();
if (typeof window !== 'undefined') {
  Object.assign(window, { dt: rentennialsTimeUtils });
}
