import { VehicleSortKey } from '@api/types/vehicles';
import { CountryCode } from '@components/common/country/types';
import { z } from 'zod';

export const ACCESS_TOKEN_COOKIE_KEY = 'rentennials_auth_token';
export const ACCESS_TOKEN_NEXT_AUTH_COOKIE_KEY = 'rentennials_auth_token_next_auth';
export const GOOGLE_ID = process.env.NEXT_PUBLIC_GOOGLE_ID || '';
export const GOOGLE_SECRET = process.env.NEXT_PUBLIC_GOOGLE_SECRET || '';
export const FACEBOOK_ID = process.env.NEXT_PUBLIC_FACEBOOK_ID || '';
export const MIN_AVERAGE_RATING_SPECIAL = process.env.NEXT_PUBLIC_MIN_AVERAGE_RATING_SPECIAL
  ? parseInt(process.env.NEXT_PUBLIC_MIN_AVERAGE_RATING_SPECIAL, 10)
  : 5;
export const MIN_NUMBER_OF_BOOKING_SPECIAL = process.env.NEXT_PUBLIC_MIN_NUMBER_OF_BOOKING_SPECIAL
  ? parseInt(process.env.NEXT_PUBLIC_MIN_NUMBER_OF_BOOKING_SPECIAL, 10)
  : 30;

export const MIN_PRICE = process.env.NEXT_PUBLIC_MIN_PRICE ? parseInt(process.env.NEXT_PUBLIC_MIN_PRICE, 10) : 8000;
export const MAX_PRICE = process.env.NEXT_PUBLIC_MAX_PRICE ? parseInt(process.env.NEXT_PUBLIC_MAX_PRICE, 10) : 80000;

export const LOGIN_ENABLED = process.env.NEXT_PUBLIC_LOGIN_ENABLED || '0';
export const ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED || '0';
export const NEWS_LETTER_ENABLED = process.env.NEXT_PUBLIC_NEWS_LETTER_ENABLED || '0';
export const MESSAGES_ENABLED = process.env.NEXT_PUBLIC_MESSAGES_ENABLED || '0';
export const PAYMENT_ENABLED = process.env.NEXT_PUBLIC_PAYMENT_ENABLED || '0';
export const NEXT_IMAGE_ENABLED = process.env.NEXT_PUBLIC_NEXT_IMAGE_ENABLED || '0';
export const AUTOMATIC_PAYMENT_APPROVAL_ENABLED = process.env.NEXT_PUBLIC_AUTOMATIC_PAYMENT_APPROVAL_ENABLED || '0';
export const WEB_SITE = process.env.NEXT_PUBLIC_WEB_SITE || 'https://qa-rentennials-web.netlify.app/';
export const CHECKING_ENABLED = process.env.NEXT_PUBLIC_CHECKING_ENABLED || '0';
export const ALLOWED_MEDIA_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];

export const RENTENNIALS_WHATSAPP_SUPPORT = process.env.NEXT_PUBLIC_WHATSAPP_PHONE || '000';

export const MIN_HOUR_GAP = process.env.NEXT_PUBLIC_MIN_HOUR_GAP
  ? parseInt(process.env.NEXT_PUBLIC_MIN_HOUR_GAP, 10)
  : 3;

export const DEFAULT_TIME = '08:00';

export const DEEP_LINK_PAGES = process.env.NEXT_PUBLIC_DEEP_LINK_PAGES
  ? z.array(z.string()).parse(JSON.parse(process.env.NEXT_PUBLIC_DEEP_LINK_PAGES))
  : [];

export const MELI_PUB_KEY = process.env.NEXT_PUBLIC_MELI_PUB_KEY || '';

export const DEFAULT_LOCALE = 'es-AR';
export const LS_TRIP_IDS_KEY = 'rentennials-trips-ids';
export const LS_STORIES_IDS_KEY = 'rentennials-stories-ids';
export const AVAILABLE_COUNTRIES = process.env.NEXT_PUBLIC_AVAIALBE_COUNTRIES
  ? z.array(z.nativeEnum(CountryCode)).parse(JSON.parse(process.env.NEXT_PUBLIC_AVAIALBE_COUNTRIES))
  : [CountryCode.AR];

export const LOCALE_COOKIE = 'NEXT_LOCALE';
export const URL_IFRAME_LISTING_OFFERS =
  process.env.NEXT_PUBLIC_URL_IFRAME_LISTING_OFFERS || 'https://blog.rentennials.app';
export const URL_IFRAME_LISTING_OFFERS_MENDOZA = process.env.NEXT_PUBLIC_URL_OFFERS_MENDOZA || 'banner-listing-mendoza';
export const URL_IFRAME_LISTING_OFFERS_MIAMI = process.env.NEXT_PUBLIC_URL_OFFERS_MIAMI || 'banner-listing-miami';
export const URL_IFRAME_LISTING_OFFERS_CANCUN = process.env.NEXT_PUBLIC_URL_OFFERS_CANCUN || 'banner-listing-cancun';
export const URL_IFRAME_CITIES = process.env.NEXT_PUBLIC_URL_IFRAME_CITIES || 'https://blog.rentennials.app';
export const URL_PATH_IFRAME_CITIES = process.env.NEXT_PUBLIC_URL_PATH_IFRAME_CITIES || 'carrusel-home/';

export const LISTING_ID_KEY = 'rentennials-listing-messages';
export const INPUT_MESSAGES_ID_KEY = 'rentennials-input-messages';
export const FORM_EDIT_PROFILE = 'form-edit-profile';

export const DEFAULT_VEHICLE_SORT_KEY: VehicleSortKey = 'default';
