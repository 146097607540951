import Head from 'next/head';

const RentennialsHead = () => {
  return (
    <Head>
      <title>Rentennials</title>
      <link rel="shortcut icon" href="/static/favicon.ico" />
    </Head>
  );
};

export default RentennialsHead;
