import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { Avatar, Box, useMediaQuery } from '@chakra-ui/react';
import Button from '@components/common/Button';
import Image from '@components/images/RentennialsNextImage';
import Language from '@components/language/Language';
import { BellIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { useUserStore } from '@store/user';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import { useAuthContext } from '@utils/hooks/useAuthContext';
import { tw } from '@utils/tailwind-utils';
import Link from 'next/link';

import RentennialsLogoSticky from '../../../public/images/Logo_Rentennials_Bottom.svg';
import RentennialsLogo from '../../../public/images/Logo_Rentennials_Top.svg';

import MenuActions, { MenuActionsOptionType } from './MenuActions';
import RegisteredMenu from './RegisteredMenu';
import UnRegisteredMenu from './UnRegisteredMenu';

interface TopMenuProps {
  hasBg?: boolean;
  className?: string;
  containerClassName?: string;
  logoClassName?: string;
  leftTitleElement?: ReactNode;
  rightTitleElement?: ReactNode;
  responsiveLogoUrl?: string;
  logoContainerClassName?: string;
  onExternalMenuOpen?: () => void;
  useExternalMenu?: boolean;
  hasMessagesUnread?: boolean;
}

const TopMenu: FC<PropsWithChildren<TopMenuProps>> = ({
  hasBg = false,
  className,
  containerClassName,
  leftTitleElement,
  rightTitleElement,
  logoClassName,
  logoContainerClassName,
  responsiveLogoUrl,
  onExternalMenuOpen,
  useExternalMenu,
  hasMessagesUnread,
  children
}) => {
  const { isUserAuthenticated } = useAuthContext();
  const userAvatar = useUserStore(({ user }) => user?.userData.pictureUrl);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [menuActionsOption, setMenuActionsOption] = useState<MenuActionsOptionType | null>(null);
  const [responsiveSize] = useMediaQuery(['(max-width: 1199px)']);

  const handleMenuOpen = (isOpen: boolean) => {
    if (onExternalMenuOpen && useExternalMenu) {
      onExternalMenuOpen();
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(isOpen);
    }
  };

  const logo = hasBg
    ? responsiveSize && responsiveLogoUrl
      ? responsiveLogoUrl
      : RentennialsLogoSticky
    : RentennialsLogo;

  return (
    <nav
      className={tw(
        'flex w-full items-center justify-between',
        {
          'bg-white shadow': hasBg
        },
        className
      )}
    >
      <div className={containerClassName}>
        <div className={tw('flex flex-1 items-center', logoContainerClassName)}>
          <div className="pr-4">
            <Link href="/">
              <Image
                priority
                alt="Rentennials Logo"
                src={logo}
                height={0}
                width={0}
                className={tw('h-[40px] max-h-[40px] min-h-[40px] w-[185px]', logoClassName)}
              />
            </Link>
          </div>
          {leftTitleElement}
        </div>

        {children}

        <div className="flex flex-1 items-center justify-end gap-x-2">
          {rightTitleElement}
          {isUserAuthenticated && isFeatureEnabled(Features.messages) && (
            <Link href="/profile/messages">
              <Box>
                <p
                  className={tw('relative', {
                    "after:absolute after:right-[-4px] after:top-[-5px] after:-translate-y-2 after:!text-3xl after:text-rentennials-indicator  after:content-['•'] mobile:hidden tablet:block lg:block":
                      hasMessagesUnread
                  })}
                />
                <BellIcon
                  className={tw('hidden h-6 w-6 text-white min-[400px]:block', {
                    'text-rentennials-primary': hasBg
                  })}
                />
              </Box>
            </Link>
          )}
          {isFeatureEnabled(Features.login) && isUserAuthenticated && (
            <div className="hidden min-[400px]:block">
              <Avatar
                loading="lazy"
                size="sm"
                showBorder
                src={isUserAuthenticated && userAvatar ? userAvatar : undefined}
                sx={{
                  border: '2px solid',
                  ...(hasBg && { borderColor: '#E2E2E2' })
                }}
              />
            </div>
          )}
          <div className="relative">
            <Button onClick={() => handleMenuOpen(true)} className="relateive hover:bg-transparent">
              <Bars3Icon
                className={tw('inline h-6 w-6 stroke-2 text-white ', {
                  'text-rentennials-primary': hasBg
                })}
              />
            </Button>
            {isUserAuthenticated && isMenuOpen && !useExternalMenu ? (
              <RegisteredMenu
                onCloseMenu={() => handleMenuOpen(false)}
                onSetMenuAction={setMenuActionsOption}
                className="left-[-220px] top-[38px] w-[250px]"
                hasMessagesUnread={hasMessagesUnread ?? false}
              />
            ) : (
              isMenuOpen &&
              !isUserAuthenticated && (
                <UnRegisteredMenu
                  onCloseMenu={() => handleMenuOpen(false)}
                  onSetMenuAction={setMenuActionsOption}
                  className="left-[-220px] top-[38px] w-[250px]"
                />
              )
            )}
            {menuActionsOption && (
              <MenuActions menuAction={menuActionsOption} onCloseMenuAction={() => setMenuActionsOption(null)} />
            )}
          </div>
          <Language hasBg={hasBg} />
        </div>
      </div>
    </nav>
  );
};

export default TopMenu;
