import { FC, useContext } from 'react';
import { PiCarProfile } from 'react-icons/pi';
import { MenuType } from '@components/common/menuTypes';
import ResponsiveMenu from '@components/common/ResponsiveMenu';
import { GlobalContext } from '@context/GlobalContext';
import {
  EnvelopeIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { DEFAULT_SEARCH_VALUES } from '@utils/dates';
import { Features, isFeatureEnabled } from '@utils/featureFlag';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import FloatingMenu from '../common/FloatingMenu';

import { MenuActionsOptionType } from './MenuActions';
import MenuItems from './MenuItems';

interface UnRegisteredMenuProps {
  onCloseMenu: () => void;
  className?: string;
  onSetMenuAction: (action: MenuActionsOptionType) => void;
}

interface UnRegisteredMenuItemsProps {
  menuItems: MenuType[];
  onLogin: () => void;
}

const UnRegisteredMenuItems: FC<UnRegisteredMenuItemsProps> = ({ menuItems, onLogin }) => {
  const { t } = useTranslation('menu');
  return (
    <>
      {isFeatureEnabled(Features.login) && (
        <>
          <ul className="px-4 font-bree-serif text-[14px] font-normal text-rentennials-menuItem">
            <li className="cursor-pointer pb-2.5" onClick={onLogin}>
              <a className="flex items-center gap-x-4">
                <UserIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" /> {t('unregistered.login')}
              </a>
            </li>
          </ul>
          <hr className="my-2" />
        </>
      )}
      <MenuItems items={menuItems} />
    </>
  );
};

const UnRegisteredMenu: FC<UnRegisteredMenuProps> = ({ onCloseMenu, className, onSetMenuAction }) => {
  const {
    responsiveState: { isDesktop }
  } = useContext(GlobalContext);
  const { t } = useTranslation('menu');

  const menuItems: MenuType[] = [
    {
      title: t('unregistered.explore'),
      isHidden: location?.pathname === '/listing',
      icon: <MagnifyingGlassIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: '/listing', query: DEFAULT_SEARCH_VALUES },
      singlePageNavigation: true,
      onClick: () => {
        onSetMenuAction('search');
        onCloseMenu();
      }
    },
    {
      title: t('unregistered.become-host'),
      icon: <PiCarProfile className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/como-alquilar-tus-vehiculos/' },
      isLink: true,
      isHidden: isDesktop,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('unregistered.faq'),
      icon: <QuestionMarkCircleIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/faqs' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('unregistered.contact-us'),
      icon: <EnvelopeIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/contacto/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    },
    {
      title: t('unregistered.legal-info'),
      icon: <InformationCircleIcon className="inline h-5 w-5 stroke-2 text-rentennials-primary" />,
      href: { pathname: 'https://blog.rentennials.app/terminos-y-condiciones/' },
      isLink: true,
      onClick: () => {
        onCloseMenu();
      }
    }
  ];

  const handleLogin = () => {
    onSetMenuAction('login');
    onCloseMenu();
  };

  return isDesktop ? (
    <FloatingMenu isOpen onClose={onCloseMenu} className={twMerge('left-[-200px] w-[250px] ', className)}>
      <UnRegisteredMenuItems menuItems={menuItems} onLogin={handleLogin} />
    </FloatingMenu>
  ) : (
    <ResponsiveMenu isOpen onClose={onCloseMenu}>
      <UnRegisteredMenuItems menuItems={menuItems} onLogin={handleLogin} />
    </ResponsiveMenu>
  );
};

export default UnRegisteredMenu;
